:root {
    --sidenav-width: 254px;
    --sidenav-h-encabezado: 64px;
    --sidenav-color-primary: #21283C;
    --sidenav-color-item: #d8d8d8;

    --toolbar-height: 64px;

    --main-background: #f1f1f1;
    
    // root (font-size: 16px;)
    --padding-16: 1rem;
    --padding-12: 0.75rem;
    --padding-8: 0.5rem;
    --padding-gutter: 1.5rem;
    --margin-gutter: 1.5rem;


    //shadow
    .shadow-b {
        box-shadow: 0 10px 30px 0 rgba(82,63,104,.06);
    }

    // Font
    .fw-400 {
        font-weight: 400;
    }

    // text-align
    .ta-r {
        text-align: right;
    }
}

@media screen and (max-width: 599px) {
    :root {
        --toolbar-height: 56px;
    }
}