// Muestra de estados formato dot
.dot-15 {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    
    &-activo {
        background: #006600;
    }
    
    &-inactivo {
        background: #660000;
    }
}


